.flex-center-start{
    display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-align: center;
    -ms-flex-align: center;
        align-items: center;
-webkit-box-pack: start;
    -ms-flex-pack: start;
        justify-content: start;
}

%overly_1{
    background-image: -moz-linear-gradient( -91deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.67) 79%, rgba(0,0,0,0.67059) 79%);
    background-image: -webkit-linear-gradient( -91deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.67) 79%, rgba(0,0,0,0.67059) 79%);
    background-image: -ms-linear-gradient( -91deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.67) 79%, rgba(0,0,0,0.67059) 79%);
  }