.slider_bg_1{
    background-image: url(../img/banner/banner.png);
}
.slider_bg_2{
    background-image: url(../img/banner/banner2.png);
}
.slider_bg_3{
    background-image: url(../img/banner/banner3.png);
}
.slider_area{
    position: relative;
    z-index: 0;
    // &::before{
    //     background: #001D38;
    //     position: absolute;
    //     left: 0;
    //     top: 0;
    //     width: 100%;
    //     height: 100%;
    //     content: '';
    //     opacity: .6;
    // }
    .single_slider{
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        padding: 190px 0;
        @media #{$mobile_device} {
            padding: 90px 0;
        }
        @media #{$tablet_device} {
            // height: 800px;
        }
        .slider_text{
            background: rgba(0, 0, 0,0.86);
            padding: 85px 80px 85px 98px;
            @media #{$mobile_device}{
                padding: 45px 30px 45px 38px;
            }
            span{
                height: 2px;
                width: 140px;
                background: #ff2424;
                display: inline-block;
                margin-bottom: 34px;
                @media #{$mobile_device}{
                    margin-bottom: 20px;
                }
            }
            h3{
            font-family: $font1;
            font-size: 60px;
            text-transform: uppercase;
            font-weight: 400;
            color: #fff;
            margin-bottom: 0;
            line-height: 80px;
             @media #{$mobile_device} {
                font-size: 30px;
                line-height: 50px;
            }
             @media #{$tablet_device} {
                font-size: 30px;
                line-height: 50px;
            }
             @media #{$mid_device} {
                font-size: 60px;
            }
             @media #{$large_device} {
                // font-size: 120px;
            }
            //  @media #{$mid_device} {
            //    font-size: 35px;
            // }
            }
            p{
                font-size: 16px;
                font-weight: 400;
                color: #FFF;
                line-height: 30px;
                margin-top: 60px;
                margin-bottom: 40px;
             @media #{$mid_device} {
                margin-top: 30px;
                margin-bottom: 20px;
             }
             @media #{$mobile_device} {
                font-size: 18px;
                margin-top: 20px;
                margin-bottom: 20px;
             }
             @media #{$tablet_device} {
                font-size: 18px;
                margin-top: 20px;
                margin-bottom: 20px;
             }
            }
            .boxed-btn3{
                color: #9f9f9f;
                background: transparent ;
                border-color: #e66543;
                &:hover{
                    background: #e66543;
                }
            }
        }
    }
    .owl-carousel .owl-nav div {
        height: 120px;
        left: 0px;
        width: 50px;
        color: #fff;
        background-color: transparent;
        @include border-radius(0);
        left: 50px;
        font-size: 14px;
        border: none;
        left: 150px;
        line-height: 120px;
        background: rgba(255,255,255,.2);
        left: 0;
        border: none !important;
        &.owl-next{
            right: 0;
            left: auto;
        }
        &:hover{
            background: rgba(255,255,255,255);
            color:#001D38 ;
            border: none;
        }
    }
}
.property_bg{
    background-image: url(../img/banner/property.png);
}
