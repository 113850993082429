.our_facilitics_area{
    position: relative;
    padding-top: 170px;
    padding-bottom: 180px;
    z-index: 0;
    @media #{$mobile_device}{
        padding-top: 80px;
        padding-bottom: 50px;
    }
    &::before{
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        width: 100%;
        height: 460px;
        z-index: -2;
        background-image: url(../img/about/about_bg.png);
    }
    &::after{
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        width: 100%;
        height: 460px;
        z-index: -1;
        background: #000;
        opacity: .8;
    }
    &.facilites_page{
        padding-bottom: 0;
        &::before{
            display: none;
        }
        &::after{
            display: none;
        }
    }
    .single_feature{
        background: #fff;
        border: 1px solid #ebebeb;
        padding: 32px 20px 65px 20px;
        @media #{$mobile_device}{
            margin-bottom: 30px;
        }
        @media #{$tablet_device}{
            margin-bottom: 30px;
        }
       .icon{
           i{
            font-size: 80px;
            @include transition(.3s);
            color:#15181d ;
           }
       } 
       h3{
           font-size: 20px;
           text-transform: uppercase;
           font-weight: 400;
           color: #15181d;
           margin-top: 26px;
            margin-bottom: 26px;
       }
       p{
           font-size: 14px;
           font-weight: 400;
           color: #828282;
           line-height: 26px;
           margin-bottom: 30px;
       }
       a{
           font-size: 14px;
           font-weight: 400;
           color: #ff573d;
           position: relative;
           padding-bottom: 8px;
           &::before{
               position: absolute;
               left: 0;
               background: #cbcbcb;
               height: 1px;
               width: 50px;
               content: '';
               bottom: 0;
               left: 50%;
               @include transform(translateX(-50%));
               @include transition(.3s);
               
           }
           &:hover::before{
            background: #15181d;
           }
       }
       &:hover{
        .icon{
            i{
                color: #ff2424 !important;
            }
        } 
       }
    }
}