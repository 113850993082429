.section_title{
    h3{
        font-size: 48px;
        font-weight: 400;
        color:#303030;
        position: relative;
        z-index: 0;
        text-transform: uppercase;
        line-height: 1;
        margin-bottom: 0px;
        line-height: 58px;
        span{
            color:#ff2424 ;
        }
        @media #{$mobile_device} {
            font-size: 30px;
            line-height: 36px;
        }
        @media #{$tablet_device} {
            font-size: 36px;
            line-height: 42px;
        }
        @media #{$mid_device} {
            font-size: 35px;
            line-height: 42px;
        }
        br{
            @media #{$mobile_device} {
                display: none;
            }
            @media #{$tablet_device} {
                display: none;
            }
            @media #{$mid_device} {
                display: none;
            }
        }
    }
    .devider{
        position: relative;
        margin-top: -2px;
       span{
           width: 50px;
           height: 2px;
           background: #e66543;
           display: inline-block;
           margin:0 3px;
       } 
       span:last-child{
        background: #303030;
        width: 14px;
       }
    }
    &.white_title{
        h3{
            color: #fff;
        }
    }
}

.mb-50{
    margin-bottom: 50px;
    @media #{$mobile_device} {
        margin-bottom: 40px;
    }
}
.mb-45{
    margin-bottom: 45px;
    @media #{$mobile_device} {
        margin-bottom: 30px;
    }
}
.mb_70{
    margin-bottom: 62px;
    @media #{$mobile_device} {
        margin-bottom: 40px;
    }
    @media #{$tablet_device} {
        margin-bottom: 40px;
    }
}
.mb-80{
    margin-bottom: 80px;
    @media #{$mobile_device} {
        margin-bottom: 40px;
    }
    @media #{$tablet_device} {
        margin-bottom: 50px;
    }
}
.mb-55{
    margin-bottom: 45px;
    @media #{$mobile_device} {
        margin-bottom: 25px;
    }
    @media #{$tablet_device} {
        margin-bottom: 25px;
    }
}
.pl-70{
    padding-left: 70px;
    @media #{$mobile_device} {
        padding-left: 0px;
    }
    @media #{$tablet_device} {
        padding-left: 0px;
    }
    @media #{$mid_device} {
        padding-left: 0px;
    }
}
